import { type GetServerSidePropsContext, type NextPageContext } from 'next';

import { getAllFeatureToggles } from '@vakantiesnl/services/src/api/features/features';
import { type FeatureToggle, createFeatureToggleRequest } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import isbot from 'isbot';
import absoluteUrl from 'next-absolute-url';
import nextcookies from 'next-cookies';

export const zooverI18nConfig = {
	locales: ['nl-NL', 'nl-BE'],
	defaultLocale: 'nl-NL',
	localeDetection: false,
	domains: [
		{
			domain: 'www.zoover.nl',
			defaultLocale: 'nl-NL',
		},
		{
			domain: 'www.zoover.be',
			defaultLocale: 'nl-BE',
		},
	],
};

export type NextContext = GetServerSidePropsContext | NextPageContext;

export function getIsBot({ req }: NextContext): boolean {
	const userAgent = typeof window === 'undefined' ? req?.headers['user-agent'] : navigator.userAgent;

	return userAgent ? isbot(userAgent) : false;
}

export async function getFeatures(ctx: NextContext): Promise<FeatureToggle | undefined> {
	const sessionId = getSessionIdFromContext(ctx);
	const mswFeatureMock = nextcookies(ctx).mswfeaturemock;

	if (typeof window === 'undefined') {
		// It is not recommended to call an API routes from the server side as this
		// creates an extra request and can cause issues with the host
		// So instead of calling the api endpoint, call the method straight away
		return await getAllFeatureToggles({ sessionId }, mswFeatureMock);
	}

	return fetchVakNL({
		input: createFeatureToggleRequest(absoluteUrl(ctx.req).origin),
		init: { headers: { sessionId } },
	})
		.then((res) => {
			if (!res.ok) {
				return undefined;
			}
			return res.json();
		})
		.catch((err) => {
			throw err;
		});
}

const sessionIdRegex = /sessionId=([^;]+)/;

function getSessionIdFromContext({ req, res }: NextContext): string {
	const sessionId = nextcookies({ req }).sessionId;

	if (sessionId) return sessionId;

	if (res) {
		const responseCookieValue = res.getHeader('Set-Cookie')?.toString();

		if (responseCookieValue) {
			const match = responseCookieValue.match(sessionIdRegex);

			if (match && match[1]) {
				return match[1];
			}
		}
	}

	throw new Error('SessionId not found');
}
