import {
	type NextPageContext,
	type GetServerSideProps,
	type GetServerSidePropsContext,
	type GetStaticProps,
	type GetStaticPropsContext,
} from 'next';

import { getAllFeatureToggles } from '@vakantiesnl/services/src/api/features/features';
import { type FeatureToggle } from '@vakantiesnl/services/src/hooks/rest/feature-toggle';
import { SSO_TOKEN_COOKIE, checkIfSsoTokenValid, getValueFromSsoToken } from '@vakantiesnl/services/src/util/authToken';
import { getDomainFromDomainLocales } from '@vakantiesnl/services/src/util/getDomainFromDomainLocales';
import { getDurationsFromContext, getPaxFromContext } from '@vakantiesnl/services/src/util/globalFilterStateHelpers';
import absoluteUrl from 'next-absolute-url';
import cookies from 'next-cookies';

import { getIsBot, getFeatures, zooverI18nConfig } from './helpers';
import type { GlobalState } from '../../pages/types';
import { isZoover } from '../brandChecks';

export interface NextPreviewPageContext extends NextPageContext {
	correlationId?: string;
	origin?: string;
}

type GetInitialProps<T> = (ctx: NextPreviewPageContext) => Promise<T>;

// Compose global state on each page request using the context. It is required to pass this down as pageProps,
// so this can be put into store(s) for use on the client.
// Two methods are provided depending on whether the page uses getServerSideProps or getInitialProps.

export function withGlobalStateGSSP(
	getServerSideProps: (state: GlobalState) => GetServerSideProps,
): GetServerSideProps {
	return async (ctx) => {
		const state = await getGlobalState(ctx);
		return getServerSideProps(state)(ctx);
	};
}

export function withGlobalStateGIP<T>(getInitialProps: (state: GlobalState) => GetInitialProps<T>): GetInitialProps<T> {
	return async (ctx) => {
		const state = await getGlobalState(ctx);
		return getInitialProps(state)(ctx);
	};
}

async function getGlobalState(ctx: NextPreviewPageContext | GetServerSidePropsContext): Promise<GlobalState> {
	const partyComposition = getPaxFromContext(ctx);
	const durations = getDurationsFromContext(ctx);
	const isBot = getIsBot(ctx);
	const { origin: dynamicOrigin } = absoluteUrl(ctx.req);

	const staticCanonicalOrigin = getDomainFromDomainLocales(
		ctx.locale,
		isZoover ? zooverI18nConfig.domains : undefined,
		isZoover ? 'https://www.zoover.nl' : 'https://www.vakanties.nl',
	);

	let features: FeatureToggle = {};

	try {
		features = (await getFeatures(ctx)) || {};
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch feature toggle data: ', err);
	}

	let userId = '';

	// Todo: When implementing login on zoover, only get cookie on the server
	// as the ssoToken cookie will be httpOnly then
	const ssoToken = cookies(ctx)[SSO_TOKEN_COOKIE];

	if (ssoToken && checkIfSsoTokenValid(ssoToken)) {
		userId = getValueFromSsoToken(ssoToken, 'user_id');
	}

	return {
		isBot,
		features,
		partyComposition,
		dynamicOrigin,
		staticCanonicalOrigin,
		userId,
		durations,
		favorites: [],
	};
}

/** Static props only needs staticCanonicalOrigin to build the site */
export type StaticPropsGlobalState = GlobalState;

export function withGlobalStateGSP(getStaticProps: (state: GlobalState) => GetStaticProps): GetStaticProps {
	return async (ctx) => {
		const state = await getGlobalStaticState(ctx);
		return getStaticProps(state)(ctx);
	};
}

async function getGlobalStaticState(ctx: GetStaticPropsContext): Promise<GlobalState> {
	const staticCanonicalOrigin = getDomainFromDomainLocales(
		ctx.locale,
		isZoover ? zooverI18nConfig.domains : undefined,
		isZoover ? 'https://www.zoover.nl' : 'https://www.vakanties.nl',
	);

	let features: FeatureToggle = {};

	try {
		features = await getAllFeatureToggles({});
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch feature toggle data: ', err);
		features = {};
	}

	return {
		staticCanonicalOrigin,
		features,
		isBot: false,
		durations: [],
		partyComposition: [],
		dynamicOrigin: '',
		favorites: [],
		userId: '',
	};
}
